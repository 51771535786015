import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Testimonies = ({ data, location, title }) => {
  const [widthWindows, setWidthWindows] = useState()

  useEffect(() => {
    setWidthWindows(window.innerWidth)
  })

  const activateSliderOne = {
    arrows: true,
    autoplay: false,
    infinite: true,
    slidesToShow: data.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      }]
  }

  return (
    <section className="container-testimonies-silder">
      <p className="container-testimonies-silder-title">
        {title}
      </p>
      <div className="container-testimonies-silder-box">
        {data.length > 1 ?
          <Slider {...activateSliderOne}>
            {data.map((selectElement, i) => {
              return (
                <section key={i} className="container-testimonies-silder-item" >
                  <div className="container-testimonies-silder-item-banner" style={{ background: selectElement.backgroundIndustrie }} >{selectElement.industrie}</div>
                  <section className="container-testimonies-silder-item-description" style={{ background: selectElement.backgroundFondo }} >
                    <div className="container-testimonies-silder-item-container-imagen">
                      <img
                        loading="lazy"
                        className="container-testimonies-silder-item-description-logo"
                        src={selectElement?.image}
                        alt="product"
                      />
                      {selectElement?.link ?
                        <Link
                          to={`${selectElement?.link}${location.search}`}
                          className="container-testimonies-silder-item-description-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72344 3.59966C4.99491 3.59966 4.40432 3.00907 4.40432 2.28054C4.40432 1.55201 4.99491 0.961426 5.72344 0.961426H19.7941C20.5226 0.961426 21.1132 1.55201 21.1132 2.28054V16.3511C21.1132 17.0797 20.5226 17.6702 19.7941 17.6702C19.0655 17.6702 18.475 17.0797 18.475 16.3511V5.46518L3.13859 20.8015C2.62345 21.3167 1.78823 21.3167 1.27308 20.8015C0.757932 20.2864 0.757932 19.4512 1.27308 18.936L16.6094 3.59966H5.72344Z" fill="#5831D8" />
                          </svg>

                        </Link> : null}
                    </div>

                    <div className="container-testimonies-silder-item-description-title">
                      {selectElement?.title}
                    </div>
                    <section className="container-testimonies-silder-item-metrics-mobile">
                      {selectElement?.metrics?.map((ele, i) => {
                        return (
                          <div
                            key={i}
                            className="container-testimonies-silder-item-metrics-mobile-item"
                          >
                            <p
                              className="container-testimonies-silder-item-metrics-mobile-item-porcentaje"
                              style={{ color: ele.color }}
                            >
                              {ele.metric}
                            </p>
                            <p className="container-testimonies-silder-item-metrics-mobile-item-info">
                              {ele.descripcion}
                            </p>
                          </div>
                        )
                      })}

                    </section>
                  </section>
                </section>
              )
            })}
          </Slider>
          : data.map((selectElement, i) => {
            return (
              <section key={i} className="container-testimonies-silder-item" style={{marginLeft: "auto", marginRight: "auto", }} >
                <div className="container-testimonies-silder-item-banner" style={{ background: selectElement.backgroundIndustrie }} >{selectElement.industrie}</div>
                <section className="container-testimonies-silder-item-description" style={{ background: selectElement.backgroundFondo }} >
                  <div className="container-testimonies-silder-item-container-imagen">
                    <img
                      loading="lazy"
                      className="container-testimonies-silder-item-description-logo"
                      src={selectElement?.image}
                      alt="product"
                    />
                    {selectElement?.link ?
                      <Link
                        to={`${selectElement?.link}${location.search}`}
                        className="container-testimonies-silder-item-description-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72344 3.59966C4.99491 3.59966 4.40432 3.00907 4.40432 2.28054C4.40432 1.55201 4.99491 0.961426 5.72344 0.961426H19.7941C20.5226 0.961426 21.1132 1.55201 21.1132 2.28054V16.3511C21.1132 17.0797 20.5226 17.6702 19.7941 17.6702C19.0655 17.6702 18.475 17.0797 18.475 16.3511V5.46518L3.13859 20.8015C2.62345 21.3167 1.78823 21.3167 1.27308 20.8015C0.757932 20.2864 0.757932 19.4512 1.27308 18.936L16.6094 3.59966H5.72344Z" fill="#5831D8" />
                        </svg>

                      </Link> : null}
                  </div>

                  <div className="container-testimonies-silder-item-description-title">
                    {selectElement?.title}
                  </div>
                  <section className="container-testimonies-silder-item-metrics-mobile">
                    {selectElement?.metrics?.map((ele, i) => {
                      return (
                        <div
                          key={i}
                          className="container-testimonies-silder-item-metrics-mobile-item"
                        >
                          <p
                            className="container-testimonies-silder-item-metrics-mobile-item-porcentaje"
                            style={{ color: ele.color }}
                          >
                            {ele.metric}
                          </p>
                          <p className="container-testimonies-silder-item-metrics-mobile-item-info">
                            {ele.descripcion}
                          </p>
                        </div>
                      )
                    })}

                  </section>
                </section>
              </section>
            )
          })
        }
      </div>
    </section>
  )
}
export default Testimonies
