/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Wally from "@components/sharedComponents/customersLogos/logos/Wally.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"

// logos testimonios
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"
import CafaeTes from "@components/sharedComponents/testimonies/images/logos/cafae.svg"

//foto testimonio desktop
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"
import cafaeDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cafae.png"

//foto testimonio desktop
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"
import cafaeMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cafae.png"

//iconos de precio
import advanced from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/digital.png"
import business from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/digitalVoice.png"


//color checked precio
import amarillo from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/amarillo.svg"
import azulino from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/azulino.svg"
import verde from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/verde.svg"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageWhatsa.jpg"

import imgPortada from "@components/pageFunctionalities/pageTwiliovsBeex/image/imgStart.png"
import imgSilder1 from "@components/pageFunctionalities/pageTwiliovsBeex/image/imgStart1.png"
import imgSilder2 from "@components/pageFunctionalities/pageTwiliovsBeex/image/imgStart2.png"

//iconos usar ticker
import dinero from "@components/pageFunctionalities/pageTwiliovsBeex/image/dinero.svg"
import pilar from "@components/pageFunctionalities/pageTwiliovsBeex/image/pilar.svg"
import parlante from "@components/pageFunctionalities/pageTwiliovsBeex/image/parlante.svg"
import auricular from "@components/pageFunctionalities/pageTwiliovsBeex/image/auricular.svg"

//iconos de precio
import ticker from "@components/pageFunctionalities/pageTwiliovsBeex/image/ticker.svg"
import twilio from "@components/pageFunctionalities/pageTwiliovsBeex/image/twilio.svg"

//logos channels
import whatsApp from "@components/pageFunctionalities/pageTwiliovsBeex/image/redes/whatsapp.svg"
import messenger from "@components/pageFunctionalities/pageTwiliovsBeex/image/redes/messenger.svg"
import instagram from "@components/pageFunctionalities/pageTwiliovsBeex/image/redes/instagram.svg"
import chat from "@components/pageFunctionalities/pageTwiliovsBeex/image/redes/chat.svg"
import call from "@components/pageFunctionalities/pageTwiliovsBeex/image/redes/call.svg"
import email from "@components/pageFunctionalities/pageTwiliovsBeex/image/redes/email.svg"
import msj from "@components/pageFunctionalities/pageTwiliovsBeex/image/redes/msj.svg"


const title = (
    <p className="guide__title">
        La mejor alternativa a Twilio para crear chatbots en WhatsApp
    </p>
)

const textDescription = (
    <p>
        Si aún no sabes cuál es la mejor opción para que puedas crear chatbots en WhatsApp, Beex está para ti como una alternativa más económica y que se adapta a todas las necesidades de tu empresa.
    </p>
)

const channelsTicker = [whatsApp, messenger, instagram, chat, msj, call]
const channelsTwilio = [whatsApp, messenger, chat, msj, call, email]


const channel = (ele) => {
    return ele.map(item => {
        return <img src={item} alt="canal" key={item} />
    })
}

const infoOne = [
    <p>Sin costo alguno</p>,
    <p>Desde US$ 9.99 por usuario</p>,
    <p>{channel(channelsTicker)}</p>,
    <p>Desde US$ 9.99 por usuario</p>,
    <p>100% cloud lo que implica menos costos de uso al no contar con un servidor físico.</p>,
    <p>Beex es una plataforma SaaS al 100%, lo que permite utilizarla libremente.</p>,
    <p>Empresas con más de 5 agentes de atención con altos volúmenes de atención.</p>,
    <p>Atención omnicanal 24/7 para cualquiera de sus planes.</p>
]

const infoTwo = [
    <p>Tiene costo adicional</p>,
    <p>Desde US$  150 por usuario</p>,
    <p>{channel(channelsTwilio)}</p>,
    <p>Desde US$  150 por usuario</p>,
    <p>Aún trabaja con arquitecturas fuera de la nube como interacciones XML.</p>,
    <p>Twilio Flex requiere código por lo que se necesita habilidades de desarrollo.</p>,
    <p>Para grandes corporaciones con un alto presupuesto a inversión en soluciones omnicanales.</p>,
    <p>Costos adicionales por el sorporte y dentro del paso de 7 y 10 días.</p>,
]

const data = {
    start: {
        title: title,
        classNameTitle: "text-title-introone",
        textbody: textDescription,
        image: imgPortada,
        classNameIntro: "frame-introatencion",
        progress: "progressatencion",
        imageSilder: [imgSilder2, imgSilder1],
        textbutton: "Quiero una asesoría",
    },
    customersDesktop: [
        Rimac,
        Culqui,
        Unifef,
        Auna,
        Astara,
        Wally,
        Proempresa,
        Civa,
        G4S,
        Savar,
        Escampa,
        Royal
    ],
    customersTablet: {
        arrayOne: [Rimac, Culqui, Unifef, Auna, Astara, Wally],
        arrayTwo: [Proempresa, Civa, G4S, Savar, Escampa, Royal],
    },
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    testimonies: [
        {
            image: KontigoTes,
            title:
                "Con WhatsApp API, mejoramos nuestro tiempo de primera respuesta en <1 minuto",
            link: "/casos-de-exito/kontigo/",
            industrie: "Banca y finanzas",
            backgroundIndustrie: "#197DF2",
            backgroundFondo: "#197DF233",
            metrics: [
                {
                    metric: "98%",
                    descripcion: "casos cerrados en primer contacto",
                    color: "#FFB800",
                },
                {
                    metric: "<1 minuto",
                    descripcion: "tiempo de primera respuesta",
                    color: "#5831D8",
                },
                {
                    metric: "x3",
                    descripcion: "mejoró el tiempo de resolución de casos",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: AstaraTes,
            title:
                "Con envíos masivos de WhatsApp logramos reducir el esfuerzo de remarketing en 93%",
            link: "/casos-de-exito/astara/",
            industrie: "Concesionarias",
            backgroundIndustrie: "#231E36",
            backgroundFondo: "#231E3633",
            metrics: [
                {
                    metric: "x3",
                    descripcion: "aumentó sus ventas digitales",
                    color: "#FFB800",
                },
                {
                    metric: "93%",
                    descripcion: "redujo su esfuerzo para remarketing",
                    color: "#5831D8",
                },
                {
                    metric: "80%",
                    descripcion: "mejoró contactabilidad con leads",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: AvivaTes,
            title:
                "Con los workflows de WhatsApp mejoramos en +30% el tiempo de agenda de citas",
            link: "/casos-de-exito/clinica-aviva/",
            industrie: "Salud",
            backgroundIndustrie: "#007791",
            backgroundFondo: "#00779133",
            metrics: [
                {
                    metric: "+30%",
                    descripcion: "mejoró el tiempo de agenda de citas",
                    color: "#FFB800",
                },
                {
                    metric: "+20%",
                    descripcion: "ahora representa WhatsApp como canal para agendar citas",
                    color: "#5831D8",
                },
            ],
        },
        {
            image: SavarTes,
            title:
                "Con WhatsApp Business API hemos reducido en 30% las consultas repetitivas de clientes",
            link: "/casos-de-exito/savar/",
            industrie: "Logística",
            backgroundIndustrie: "#004F96",
            backgroundFondo: "#004F9633",
            metrics: [
                {
                    metric: "+20%",
                    descripcion: "aumentó la satisfacción de sus clientes",
                    color: "#FFB800",
                },
                {
                    metric: "+30%",
                    descripcion: "se redujeron las consultas repetitivas",
                    color: "#5831D8",
                },
                {
                    metric: "25%",
                    descripcion: "mejoró el tiempo de resolución de casos",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: CafaeTes,
            title:
                "Con los chatbots de WhatsApp, cerramos +60% de consultas sin necesidad de agentes",
            textbody: "CAFAE-SE, institución de alcance nacional para el sector educación público, gestiona sus miles de interacciones diarias por WhatsApp, con chatbots y uso multiagente.",
            name: "Johans Herencia",
            post: "Responsable de Marketing Digital",
            link: "/casos-de-exito/cafae-se/",
            imgDesktop: cafaeDesktop,
            imgMobile: cafaeMobile,
            metrics: [
                {
                    metric: "60%",
                    descripcion: "de casos cerrados por chatbots",
                    color: "#FFB800",
                },
                {
                    metric: "70%",
                    descripcion: "de reducción de tiempo de respuesta",
                    color: "#5831D8",
                },
            ],
        },

    ],

    useScore: [
        {
            icon: dinero,
            title: "No te genera gastos de más",
            text:
                "Los costos de Beex son transparentes, lo que ves es lo que vas a pagar. Tampoco te generamos costos adicionales por adquirir la API de WhatsApp Business.",
        },
        {
            icon: pilar,
            title: "Se integra con cualquier base de datos",
            text:
                "Cualquier repositorio de información se podrá integrar con Beex. Nosotros no creemos en las limitaciones, por eso, contamos con una potente estructura de APIs y webhooks.",
        },
        {
            icon: parlante,
            title: "Ideal para cualquiera de tus campañas",
            text:
                "Atención al cliente, marketing, ventas, soporte y mucho más. Cualquier proceso que necesites optimizar y automatizar será posible con Beex.",
        },
        {
            icon: auricular,
            title: "Soporte único en el mercado",
            text:
                "Nadie te entenderá tan bien como nuestro equipo de soporte. Nuestros agentes se comprometen contigo desde principio a fin para tener mejores resultados.",
        },
    ],

    pricing: [
        {
            title: "DIGITAL",
            price: (
                <p className="pricing-number">
                    $40.00 <span className="perUser">POR USUARIO</span>
                </p>
            ),
            typePrice: "yes",
            classtitle: "card-title-inbox",
            img: advanced,
            info: [
                "5 usuarios mínimos al mes",
                "Todos los canales digital disponibles",
                "Chatbots ilimitados",
                "Campañas masivas de WhatsApp, SMS y correo",
                "Tickets y contactos ilimitados",
                "Acceso a automatizaciones, API e IA",
            ],
            link:
                "https://app.beexconv.com/signup?plan=611fd46372992c4324a281d4&license=advanced",
            color: "#f7d731",
            checked: amarillo,
        },
        {
            title: "DIGITAL + VOICE",
            price: (
                <p className="pricing-number" style={{ color: "#4894CD" }}>
                    $60.00 <span className="perUser">POR USUARIO</span>
                </p>
            ),
            typePrice: "yes",
            classtitle: "card-title-inbox",
            img: business,
            info: [
                "10 usuarios mínimos al mes",
                "Canales digitales y de voz disponibles",
                "Chatbots ilimitados",
                "Campañas masivas de WhatsApp, SMS y correo",
                "Tickets y contactos ilimitados",
                "Acceso a automatizaciones, API e IA",
            ],
            link:
                "https://app.beexconv.com/signup?plan=611fd46372992c4324a281d5&license=business",
            color: "#f7d731",
            checked: azulino,
        },
    ],
    bannerVideo: {
        title: "¿Cómo obtener la API de WhatsApp Business?",
        description: "<p>En este video aprenderás cómo obtener la API de WhatsApp Business, una herramienta poderosa para que cualquier empresa pueda mejorar la comunicación con tus clientes.<br /><br />Nuestra Key Account Manager, Katherine Cantelmi, nos muestra paso a paso cómo registrarte, cumplir con los requisitos necesarios y configurar la API para que funcione correctamente.</p>",
        image: imageVideo
    },
    contacts: {
        title: "Optimiza tu atención con WhatsApp",
        textbody:
            "Queremos ayudarte a que puedas a utilizar esta herramienta sin problema alguno. Agenda una reunión con nosotros y resolvemos todas tus dudas.",
        btn: "Agenda una reunión gratis",
        href: "/hablemos/",
    },
    compare: [
        {
            img: ticker,
            info: infoOne,
            title: "ticker"
        }, {
            img: twilio,
            info: infoTwo,
            title: "twilio"
        },
    ],
}

export default data
